import { amgApi } from "@/service/axios"

class GptPromptsService {
    path = "management/settings/general/gpt-prompts"

    async execute(action, params = {}) {
        try {
            const data = await amgApi.post(
                `${this.path}/${action}`,
                params
            )
            return data
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export default new GptPromptsService()
