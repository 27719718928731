<template>
  <div class="gpt-prompts">
    <div class="row">
      <div class="col-12">
        <b-card>
          <b-card-header>
            <h4 class="card-title">GPT Prompts</h4>
          </b-card-header>
          <b-card-body>
            <b-table-simple striped hover>
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="field in fields"
                    :key="field.key"
                    :class="field.thClass"
                  >
                    {{ field.label }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="prompt in prompts" :key="prompt.key">
                  <b-td
                    ><span class="text-capitalize">
                      {{ underscoeToSpace(prompt.key) }}
                    </span></b-td
                  >
                  <b-td>
                    <span v-if="!prompt.editable"> {{ prompt.prompt }}</span>
                    <b-form-textarea
                      v-else
                      v-model="prompt.prompt"
                      :rows="3"
                      :max-rows="6"
                      placeholder="Enter prompt"
                    ></b-form-textarea>
                  </b-td>
                  <b-td>
                    <div class="d-flex justify-content-center">
                      <b-button
                        v-if="!prompt.editable"
                        size="sm"
                        @click="prompt.editable = !prompt.editable"
                        variant="outline-primary"
                      >
                        Edit <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-if="prompt.editable"
                        size="sm"
                        variant="outline-primary"
                        @click="updateGptPrompt(prompt)"
                      >
                        Update <feather-icon icon="SaveIcon" />
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import GptService from "@/views/management/views/settings/views/gpt-prompts/service/index.js";
export default {
  name: "GptPrompts",
  data() {
    return {
      prompts: [],
      fields: [
        { key: "key", label: "Key", thClass: "w-15 text-left" },
        { key: "prompt", label: "Prompt", thClass: "w-70 text-left" },
        { key: "actions", label: "Actions", thClass: "w-15 text-center" },
      ],
    };
  },
  mounted() {
    this.getGptPrompts();
  },
  methods: {
    underscoeToSpace(text) {
      return text.replace(/_/g, " ");
    },
    async getGptPrompts() {
      try {
        this.addPreloader();
        const response = await GptService.execute("index");
        response.data.forEach((prompt) => {
          prompt.editable = false;
        });
        this.prompts = response.data;
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async updateGptPrompt(prompt) {
      try {
        this.addPreloader();
        const { data } = await GptService.execute("update", prompt);
        prompt.editable = false;
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          data.message
        );
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gpt-prompts {
  .card-title {
    font-size: 1.5rem;
  }
  .w-15 {
    width: 15% !important;
  }
  .w-70 {
    width: 70% !important;
  }
}
</style>